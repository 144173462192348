import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter)

const routes = [
    { path: '/', component: () => import('./components/AlterarSenha.vue') },
    { path: '/validar-documento', redirect: '/validar-documento/0/' },
    { path: '/validar-documento/:ds_hash/', component: () => import('./components/ValidarDocumento.vue') },
    { path: '*', redirect: '/' }
]

const router = new VueRouter({ 
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router